import { useContext, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import FiltersContext from "../../Context";

const Banner = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState("Result: none");
  const { rangeFilterList, setRangeFilterList } = useContext(FiltersContext);
  const navigate = useNavigate();

  const onDataSubmit = () => {
    navigate("/buy", {
      state: {
        searchValue,
        isTrue: true,
      },
    });
  };

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const formattedAddress = place.formatted_address;
      setSearchValue(formattedAddress);
    }
  }
  return (
    <section className="banner">
      <div className="item">
        <div className="container">
          <div className="banner-block">
            <h1>
              Find your Home with <br />
              Selzer realty & Associates
            </h1>
          </div>
          <div className="Search-block">
            <form>
              <div className="form-group">
                <label>Location</label>
                <Autocomplete
                  className="form-field"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onLoad}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Location"
                  />
                </Autocomplete>
              </div>
              <div className="form-group">
                <label>Type</label>
                <select
                  onChange={(e) => {
                    setRangeFilterList((prevState) => {
                      return {
                        ...prevState,
                        filterPropertyTypeData: [e.target.value],
                      };
                    });
                  }}
                  defaultValue="0"
                  className="form-control"
                >
                  <option value="0" disabled>
                    Property Type
                  </option>
                  <option value="Residential">Residential</option>
                  <option value="Business Opportunity">
                    Business Opportunity
                  </option>
                  <option value="Commercial Sale">Commercial Sale</option>
                  <option value="Commercial Lease">Commercial Lease</option>
                  <option value="Residential Income">Residential Income</option>
                  <option value="Land">Land</option>
                  <option value="Mobile in Park/Floating Home">
                    Mobile in Park/Floating Home
                  </option>
                  <option value="Residential Lease">Residential Lease</option>
                  <option value="Manufactured In Park">
                    Manufactured In Park
                  </option>
                </select>
              </div>
              <div className="form-group">
                <label>Category</label>
                <select className="form-control">
                  <option>Property Category</option>
                  <option>Property Category-1</option>
                  <option>Property Category-2</option>
                  <option>Property Category-3</option>
                </select>
              </div>
              <button className="btn" onClick={onDataSubmit} type="submit">
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
