const ComingSoon = () => {
  return (
    <div
      style={{
        minHeight: "calc(100vh - 76px)",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px",
        textAlign: "center",
      }}
    >
      <img
        style={{ maxWidth: "350px" }}
        src="../assets/images/logo.png"
        alt=""
      />
      <div>
        <h1 style={{ color: "#df1a33" }}>Coming Soon</h1>
        <p>Our website is under construction.</p>
      </div>
    </div>
  );
};

export default ComingSoon;
