import { useEffect } from "react";
import "./index.css";
import $ from "jquery";
import { Link } from "react-router-dom";

const Agent = ({ agent }) => {
  const { id, profileImg, name, occupation, cell, phone, languages } = agent;

  useEffect(() => {
    $(".agent .lang").matchHeight({ property: "height" });
  }, []);

  return (
    <div className="agent">
      <Link to={`/agent-details/${id}`} className="agent-img">
        <img src={profileImg} alt="" />
      </Link>
      <h5>{name}</h5>
      <h6>{occupation}</h6>
      <p>
        Cell Phone : <a href="tel:(707) 367-1102">{cell}</a>
      </p>
      <p>
        Office Phone : <a href="tel:(707) 467-3636">{phone}</a>
      </p>
      <p className="lang">
        {languages[0] ? `Known Languages : ${languages.join(", ")}` : ""}
      </p>
      <ul>
        <li>
          <Link to={`/agent-details/${id}`}>Contact This Realtor</Link>
        </li>
        <li>
          <Link to={`/agent-details/${id}`}>View Active Listings</Link>
        </li>
      </ul>
    </div>
  );
};

export default Agent;
