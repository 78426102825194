import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import "./index.css";

const HeaderMenuList = [
  {
    label: "Buy",
    path: "/buy",
  },
  {
    label: "Rent",
    path: "/rent",
  },
  {
    label: "Sell",
    path: "/sell",
  },
  {
    label: "Home Loans",
    path: "/home-loans",
  },
  {
    label: "Agent Finder",
    path: "/agent-finder",
  },
  {
    label: "List your rental",
    path: "/list-your-rental",
  },
  {
    label: "Advertise",
    path: "/advertise",
  },
  {
    label: "Help",
    path: "/help",
  },
  {
    label: "Sign In",
    path: "/sign-in",
  },
];

function Header() {
  const onCloseMenu = () => {
    if (window.innerWidth < 992) {
      var forEach = function (t, o, r) {
        if ("[object Object]" === Object.prototype.toString.call(t))
          for (var c in t)
            Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
        else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
      };
      var hamburgers = document.querySelectorAll(".mobile-toggle");
      if (hamburgers.length > 0) {
        forEach(hamburgers, (hamburger) => {
          $(hamburger).hasClass("is-active")
            ? $(hamburger).removeClass("is-active")
            : $(hamburger).addClass("is-active");
          $("#nav").hasClass("is-active")
            ? $("#nav").removeClass("is-active")
            : $("#nav").addClass("is-active");
          $("body").hasClass("overflow-y")
            ? $("body").removeClass("overflow-y")
            : $("body").addClass("overflow-y");
        });
      }
    }
  };

  return (
    <header>
      <div className="container-fluid">
        <div className="header-block">
          <Link to="/" className="logo">
            <img src="../../assets/images/logo.png" alt="" />
          </Link>
          <nav id="nav">
            <ul className="navbar-nav">
              {HeaderMenuList.map((menu, index) => (
                <li key={index}>
                  <NavLink
                    onClick={onCloseMenu}
                    activeclassname="is-active"
                    to={menu.path}
                  >
                    {menu.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <a href="tel:8007363119" className="btn large-btn">
            <i className="fa fa-phone"></i>(800) 736-3119
          </a>
          <button
            onClick={onCloseMenu}
            className="mobile-toggle mobile-toggle--squeeze"
            type="button"
          >
            <span className="mobile-toggle-box">
              <span className="mobile-toggle-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
