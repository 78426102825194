import { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Autocomplete } from "@react-google-maps/api";
import $ from "jquery";
import Pagination from "rc-pagination/lib/Pagination";
import { useLocation } from "react-router-dom";
import { fromAddress } from "react-geocode";
import MapComponent from "../../Components/PropertiesMap/MapComponent";
import PropertyItem from "../../Components/PropertyItem";
import "./index.css";
import FeatureItem from "../../Components/FeatureItem";
import { GOOGLE_MAPS_KEY, PROPERTIES_URL } from "../../Global/API's";
import PropertyDetailQuickView from "../../Components/PropertyDetailQuickView";
import FiltersContext from "../../Context";

function MultiDropdown(props) {
  const { eachFilterItem, updateMultiFilterData, filterData, listId } = props;
  const { id, label, isChecked } = eachFilterItem;
  return (
    <li
      className={isChecked ? "propertyType-item active" : "propertyType-item"}
    >
      <label htmlFor={id} className="type-label">
        {label}
      </label>
      <input
        checked={isChecked}
        id={id}
        onChange={() => updateMultiFilterData(id, label, filterData, listId)}
        type="checkbox"
        className="type-input"
        value={id}
      />
    </li>
  );
}

const initialPropertyTypeData = [
  {
    id: uuidv4(),
    label: "Residential",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Business Opportunity",
    isChecked: true,
  },

  {
    id: uuidv4(),
    label: "Commercial Sale",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Commercial Lease",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Residential Income",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Land",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Mobile in Park/Floating Home",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Residential Lease",
    isChecked: true,
  },
  {
    id: uuidv4(),
    label: "Manufactured In Park",
    isChecked: true,
  },
];

const BuyProperties = (props) => {
  const [paginationObj, setPaginationObj] = useState({
    current: 1,
    pageSize: 10,
  });
  const [boundaryVal, setBoundaryVal] = useState(null);
  const [initialPropertiesData, setinitialPropertiesData] = useState([]);
  const [propertiesData, setPropertiesData] = useState([]);
  const [propertyTypeData, setPropertyTypeData] = useState(
    initialPropertyTypeData
  );
  const [selectedSearchValue, setSelectedSearchValue] = useState({
    lat: 39.31168,
    lng: -123.21599,
    placeId: "ChIJ29UCh2F4gYARdR2yVhO8-wM",
  });
  const [searchResult, setSearchResult] = useState("Result: none");
  const [isLoading, setIsLoading] = useState(true);
  const propertiesMainRef = useRef(null);
  const [contentView, setContentView] = useState("Map");
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeModalId, setActiveModalId] = useState({ id: null, address: "" });
  const { rangeFilterList, setRangeFilterList } = useContext(FiltersContext);
  const { state } = useLocation();

  const getPropertiesData = async () => {
    setIsLoading(true);
    const propertyListurl = `${PROPERTIES_URL}?limit=200&skip=0`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(propertyListurl, options);
      if (response.ok) {
        const fetchedData = await response.json();
        setinitialPropertiesData(fetchedData);
        setPropertiesData(fetchedData);
        setIsLoading(false);
        onFilterPropertiesList(fetchedData);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  useEffect(() => {
    const { isActivePropertiesPage } = props;
    isActivePropertiesPage();
    getPropertiesData();
    return () => {
      const { isInActivePropertiesPage } = props;
      isInActivePropertiesPage();
      setRangeFilterList({
        minPrice: "",
        maxPrice: "",
        minBeds: "",
        maxBeds: "",
        minBaths: "",
        maxBaths: "",
        minDaysOnSite: "",
        maxDaysOnSite: "",
        minSqft: "",
        maxSqft: "",
        filterPropertyTypeData: [
          "Residential",
          "Business Opportunity",
          "Commercial Sale",
          "Commercial Lease",
          "Residential Income",
          "Land",
          "Mobile in Park/Floating Home",
          "Residential Lease",
          "Manufactured In Park",
        ],
      });
    };
  }, []);

  const onToggleMenu = (e) => {
    const eleId = e.target.className.split(" ");
    $("." + eleId[0]).toggleClass("open");
    $("." + eleId[0])
      .parents(".filterDropdown")
      .siblings(".filterDropdown")
      .children(".open")
      .removeClass("open");
  };

  const updateMultiFilterData = (id, label, filterData, listId) => {
    const modifiedData = filterData.map((eachFilterItem) => {
      return eachFilterItem.id === id
        ? { ...eachFilterItem, isChecked: !eachFilterItem.isChecked }
        : eachFilterItem;
    });

    if (listId === "propertyTypeFilter") {
      setPropertyTypeData(modifiedData);
    }
  };

  const onRangeChange = (e) => {
    const value = isNaN(parseInt(e.target.value))
      ? ""
      : parseInt(e.target.value);
    if (e.target.className === "min-price" || e.target.id === "minPrice") {
      if (e.target.id === "") {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minPrice: onCompareValues(value, prevList.maxPrice).minVal,
          maxprice: onCompareValues(value, prevList.maxPrice).maxVal,
        }));
      } else {
        setRangeFilterList((prevList) => ({ ...prevList, minPrice: value }));
      }
    }
    if (e.target.className === "max-price" || e.target.id === "maxPrice") {
      if (e.target.id === "") {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minPrice: onCompareValues(prevList.minPrice, value).minVal,
          maxPrice: onCompareValues(prevList.minPrice, value).maxVal,
        }));
      } else {
        setRangeFilterList((prevList) => ({ ...prevList, maxPrice: value }));
      }
    }
    if (e.target.className === "min-beds" || e.target.id === "minBeds") {
      if (e.target.id === "") {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minBeds: onCompareValues(value, prevList.maxBeds).minVal,
          maxBeds: onCompareValues(value, prevList.maxBeds).maxVal,
        }));
      } else {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minBeds: value,
        }));
      }
    }
    if (e.target.className === "max-beds" || e.target.id === "maxBeds") {
      if (e.target.id === "") {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minBeds: onCompareValues(prevList.minBeds, value).minVal,
          maxBeds: onCompareValues(prevList.minBeds, value).maxVal,
        }));
      } else {
        setRangeFilterList((prevList) => ({
          ...prevList,
          maxBeds: value,
        }));
      }
    }
    if (e.target.className === "min-baths" || e.target.id === "minBaths") {
      if (e.target.id === "") {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minBaths: onCompareValues(value, prevList.maxBaths).minVal,
          maxBaths: onCompareValues(value, prevList.maxBaths).maxVal,
        }));
      } else {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minBaths: value,
        }));
      }
    }
    if (e.target.className === "max-baths" || e.target.id === "maxBaths") {
      if (e.target.id === "") {
        setRangeFilterList((prevList) => ({
          ...prevList,
          minBaths: onCompareValues(prevList.minBaths, value).minVal,
          maxBaths: onCompareValues(prevList.minBaths, value).maxVal,
        }));
      } else {
        setRangeFilterList((prevList) => ({
          ...prevList,
          maxBaths: value,
        }));
      }
    }
    if (e.target.id === "minDaysOnSite") {
      setRangeFilterList((prevList) => ({
        ...prevList,
        minDaysOnSite: value,
      }));
    }
    if (e.target.id === "maxDaysOnSite") {
      setRangeFilterList((prevList) => ({
        ...prevList,
        maxDaysOnSite: value,
      }));
    }
  };

  const onFilterPropertiesData = (bounds) => {
    setIsLoading(true);
    setPaginationObj({ current: 1, pageSize: 10 });
    const {
      minPrice,
      maxPrice,
      minBeds,
      maxBeds,
      minBaths,
      maxBaths,
      filterPropertyTypeData,
    } = rangeFilterList;
    const filterPropertiesData = initialPropertiesData.filter(
      (eachProperty) => {
        return (
          (Number(minPrice) === 0
            ? true
            : eachProperty.price >= Number(minPrice)) &&
          (Number(maxPrice) === 0
            ? true
            : eachProperty.price <= Number(maxPrice)) &&
          (Number(minBeds) === 0
            ? true
            : eachProperty.bedrooms >= Number(minBeds)) &&
          (Number(maxBeds) === 0
            ? true
            : eachProperty.bedrooms <= Number(maxBeds)) &&
          (Number(minBaths) === 0
            ? true
            : eachProperty.fullBaths >= Number(minBaths)) &&
          (Number(maxBaths) === 0
            ? true
            : eachProperty.fullBaths <= Number(maxBaths)) &&
          (filterPropertyTypeData[0] === undefined
            ? true
            : filterPropertyTypeData.includes(eachProperty.propType))
        );
      }
    );
    setBoundaryVal(bounds);
    onUpdateData();
    setPropertiesData(filterPropertiesData);
  };

  const onUpdateData = () => {
    propertiesMainRef.current.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const onApplyFilters = () => {
    onFilterPropertiesList();
    setTimeout(() => {
      $(
        ".properties-list .sub-header .filter-group .filterDropdown > button"
      ).removeClass("open");
      $(".filter-group").removeClass("open");
    }, 1000);
  };

  const onCompareValues = (minVal, maxVal) => {
    if (
      (minVal > maxVal && minVal !== "" && maxVal !== "") ||
      (minVal === "" && maxVal !== "")
    ) {
      return { minVal: maxVal, maxVal: minVal };
    } else {
      return { minVal, maxVal };
    }
  };

  const onCompareValuesList = () => {
    setRangeFilterList({
      ...rangeFilterList,
      minPrice: onCompareValues(
        rangeFilterList.minPrice,
        rangeFilterList.maxPrice
      ).minVal,
      maxPrice: onCompareValues(
        rangeFilterList.minPrice,
        rangeFilterList.maxPrice
      ).maxVal,
      minBeds: onCompareValues(rangeFilterList.minBeds, rangeFilterList.maxBeds)
        .minVal,
      maxBeds: onCompareValues(rangeFilterList.minBeds, rangeFilterList.maxBeds)
        .maxVal,
      minBaths: onCompareValues(
        rangeFilterList.minBaths,
        rangeFilterList.maxBaths
      ).minVal,
      maxBaths: onCompareValues(
        rangeFilterList.minBaths,
        rangeFilterList.maxBaths
      ).maxVal,
      minDaysOnSite: onCompareValues(
        rangeFilterList.minDaysOnSite,
        rangeFilterList.maxDaysOnSite
      ).minVal,
      maxDaysOnSite: onCompareValues(
        rangeFilterList.minDaysOnSite,
        rangeFilterList.maxDaysOnSite
      ).maxVal,
    });
  };

  const onPropertyTypeChange = () => {
    let filterPropertyTypeDatas = [];
    for (let i of propertyTypeData) {
      if (i.isChecked) {
        filterPropertyTypeDatas = [...filterPropertyTypeDatas, i.label];
      }
    }
    $(
      ".properties-list .sub-header .filter-group .filterDropdown > button"
    ).removeClass("open");
    $(".filter-group").removeClass("open");

    setRangeFilterList({
      ...rangeFilterList,
      filterPropertyTypeData: filterPropertyTypeDatas,
    });
  };

  const onFilterPropertiesList = (propertiesData = initialPropertiesData) => {
    setIsLoading(true);
    setPaginationObj({ current: 1, pageSize: 10 });
    const {
      minPrice,
      maxPrice,
      minBeds,
      maxBeds,
      minBaths,
      maxBaths,
      filterPropertyTypeData,
    } = rangeFilterList;
    const filterPropertiesList = propertiesData.filter((eachProperty) => {
      return (
        (Number(minPrice) === 0
          ? true
          : eachProperty.price >= Number(minPrice)) &&
        (Number(maxPrice) === 0
          ? true
          : eachProperty.price <= Number(maxPrice)) &&
        (Number(minBeds) === 0
          ? true
          : eachProperty.bedrooms >= Number(minBeds)) &&
        (Number(maxBeds) === 0
          ? true
          : eachProperty.bedrooms <= Number(maxBeds)) &&
        (Number(minBaths) === 0
          ? true
          : eachProperty.fullBaths >= Number(minBaths)) &&
        (Number(maxBaths) === 0
          ? true
          : eachProperty.fullBaths <= Number(maxBaths)) &&
        (filterPropertyTypeData[0] === undefined
          ? true
          : filterPropertyTypeData.includes(eachProperty.propType))
      );
    });
    onUpdateData();
    setPropertiesData(filterPropertiesList);
  };

  useEffect(() => {
    onFilterPropertiesList();
    const filteredPropertyTypeData = initialPropertyTypeData.map((item) => {
      if (rangeFilterList.filterPropertyTypeData.includes(item.label)) {
        return { ...item, isChecked: true };
      } else {
        return { ...item, isChecked: false };
      }
    });
    setPropertyTypeData(filteredPropertyTypeData);
  }, [rangeFilterList.filterPropertyTypeData]);

  useEffect(() => {
    if (state) {
      if (state.searchValue !== "") {
        handleGeocode(state?.searchValue);
      }
    }
  }, [state]);

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const formattedAddress = place.formatted_address;
      handleGeocode(formattedAddress);
    }
  }

  const handleGeocode = (address) => {
    fromAddress(address, GOOGLE_MAPS_KEY)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setSelectedSearchValue({
          lat,
          lng,
          placeId: response.results[0].place_id,
        });
      })
      .catch(() => {
        alert("Please enter a valid location");
      });
  };

  const onHandleView = (e) => {
    setContentView(e.target.value);
    setIsLoading(true);
    onUpdateData();
  };

  const {
    minPrice,
    maxPrice,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minDaysOnSite,
    maxDaysOnSite,
    minSqft,
    maxSqft,
  } = rangeFilterList;

  const renderLoadingView = () => {
    return (
      <div className="loader">
        <div className="loading-two"></div>
      </div>
    );
  };

  const renderPropertyItemView = () => {
    return (
      <>
        <ul className="properties-list-block">
          {propertiesData
            .slice(
              (paginationObj.current - 1) * paginationObj.pageSize,
              paginationObj.pageSize * paginationObj.current
            )
            .map((eachProperty) => (
              <PropertyItem
                setActiveModalId={setActiveModalId}
                isRedirect={false}
                handleActiveMarker={handleActiveMarker}
                eachProperty={eachProperty}
                key={eachProperty._id}
              />
            ))}
        </ul>
        <Pagination
          pageSize={10}
          total={propertiesData?.length}
          showTitle={false}
          current={paginationObj.current}
          onChange={(current, pageSize) => {
            propertiesMainRef.current.scrollTo({ top: 0, behavior: "smooth" });
            setPaginationObj({ current: current, pageSize: pageSize });
          }}
        />
      </>
    );
  };
  const renderPropertyItemListView = () => {
    return (
      <>
        <ul className="properties-list-block">
          {propertiesData
            .slice(
              (paginationObj.current - 1) * paginationObj.pageSize,
              paginationObj.pageSize * paginationObj.current
            )
            .map((eachProperty) => (
              <FeatureItem eachProperty={eachProperty} key={eachProperty._id} />
            ))}
        </ul>
        <Pagination
          pageSize={10}
          total={propertiesData?.length}
          showTitle={false}
          current={paginationObj.current}
          onChange={(current, pageSize) => {
            propertiesMainRef.current.scrollTo({ top: 0, behavior: "smooth" });
            setPaginationObj({ current: current, pageSize: pageSize });
          }}
        />
      </>
    );
  };
  const renderNoHomesView = () => {
    return (
      <div className="no-listing-block">
        <figure>
          <img src="../assets/images/no-properties-list.png" alt="" />
        </figure>
        <h5>No Properties in this area</h5>
      </div>
    );
  };

  const renderSwitchView = () => {
    if (isLoading) {
      return renderLoadingView();
    } else if (propertiesData.length === 0) {
      return renderNoHomesView();
    } else if (contentView === "Map" || contentView === "Grid") {
      return renderPropertyItemView();
    } else if (contentView === "List") {
      return renderPropertyItemListView();
    }
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <>
      <section className="properties-list">
        <div className="sub-header">
          <div className="search-form">
            <div className="input-block">
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                <input
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setSelectedSearchValue({
                        lat: 39.31168,
                        lng: -123.21599,
                        placeId: "ChIJ29UCh2F4gYARdR2yVhO8-wM",
                      });
                    }
                  }}
                  placeholder="City, Neighborhood, Address, ZIP or MLS ID"
                  type="search"
                  className="form-control"
                />
              </Autocomplete>
            </div>
          </div>
          <button
            type="button"
            className="sub-menu-btn"
            onClick={() => {
              $(".filter-group").addClass("open");
            }}
          >
            Filters <i className="fa fa-angle-down" aria-hidden="true"></i>
          </button>
          <div className="filter-group">
            <button
              className="close-filter-btn"
              onClick={() => {
                $(".filter-group").removeClass("open");
              }}
              type="button"
            >
              <svg
                viewBox="0 0 32 32"
                aria-hidden="true"
                focusable="false"
                role="img"
                className="Icon-c11n-8-100-2__sc-13llmml-0 fBvdWz"
              >
                <path
                  stroke="none"
                  d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"
                ></path>
              </svg>
            </button>
            <div className="filterDropdown">
              <button
                onClick={onToggleMenu}
                className="priceFilter"
                type="button"
              >
                Price <i className="fa fa-angle-down"></i>
              </button>
              <div className="filterDropdown-menu">
                <div className="form-fileds">
                  <div className="form-group">
                    <div className="form-inputs">
                      <label>$</label>
                      <input
                        value={minPrice}
                        id="minPrice"
                        onChange={onRangeChange}
                        onBlur={onCompareValuesList}
                        className="form-control"
                        placeholder="Min Price"
                      />
                    </div>
                  </div>
                  <span className="separator">-</span>
                  <div className="form-group">
                    <div className="form-inputs">
                      <label>$</label>
                      <input
                        value={maxPrice}
                        onChange={onRangeChange}
                        onBlur={onCompareValuesList}
                        id="maxPrice"
                        className="form-control"
                        placeholder="Max Price"
                      />
                    </div>
                  </div>
                </div>
                <button type="button" onClick={onApplyFilters} className="btn">
                  Apply
                </button>
              </div>
            </div>
            <div className="filterDropdown">
              <button
                onClick={onToggleMenu}
                className="bedsFilter"
                type="button"
              >
                Beds <i className="fa fa-angle-down"></i>
              </button>
              <div className="filterDropdown-menu">
                <div className="form-fileds">
                  <div className="form-group">
                    <div className="form-inputs">
                      <input
                        id="minBeds"
                        value={minBeds}
                        onBlur={onCompareValuesList}
                        onChange={onRangeChange}
                        autoFocus
                        className="form-control"
                        placeholder="Min Beds"
                      />
                    </div>
                  </div>
                  <span className="separator">-</span>
                  <div className="form-group">
                    <div className="form-inputs">
                      <input
                        id="maxBeds"
                        value={maxBeds}
                        onBlur={onCompareValuesList}
                        onChange={onRangeChange}
                        className="form-control"
                        placeholder="Max Beds"
                      />
                    </div>
                  </div>
                </div>
                <button type="button" onClick={onApplyFilters} className="btn">
                  Apply
                </button>
              </div>
            </div>
            <div className="filterDropdown">
              <button
                onClick={onToggleMenu}
                className="bathsFilter"
                type="button"
              >
                Baths <i className="fa fa-angle-down"></i>
              </button>
              <div className="filterDropdown-menu">
                <div className="form-fileds">
                  <div className="form-group">
                    <div className="form-inputs">
                      <input
                        id="minBaths"
                        value={minBaths}
                        onBlur={onCompareValuesList}
                        onChange={onRangeChange}
                        autoFocus
                        className="form-control"
                        placeholder="Min Baths"
                      />
                    </div>
                  </div>
                  <span className="separator">-</span>
                  <div className="form-group">
                    <div className="form-inputs">
                      <input
                        id="maxBaths"
                        value={maxBaths}
                        onBlur={onCompareValuesList}
                        onChange={onRangeChange}
                        className="form-control"
                        placeholder="Max Baths"
                      />
                    </div>
                  </div>
                </div>
                <button type="button" onClick={onApplyFilters} className="btn">
                  Apply
                </button>
              </div>
            </div>
            <div className="filterDropdown">
              <button
                onClick={onToggleMenu}
                type="button"
                className="propertyTypeFilter"
              >
                Property Type <i className="fa fa-angle-down"></i>
              </button>
              <div className="filterDropdown-menu multi-dropdown-list">
                <ul className="multi-dropdown-items">
                  {propertyTypeData.map((eachFilterItem) => (
                    <MultiDropdown
                      listId="propertyTypeFilter"
                      filterData={propertyTypeData}
                      eachFilterItem={eachFilterItem}
                      updateMultiFilterData={updateMultiFilterData}
                      key={eachFilterItem.id}
                    />
                  ))}
                </ul>
                <button
                  type="button"
                  onClick={onPropertyTypeChange}
                  className="btn"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <ul className="content-switch-group">
            <li>
              <input
                onChange={onHandleView}
                type="radio"
                value="Map"
                name="content-swith"
                defaultChecked
              />
              <label>
                <i className="fa fa-map" aria-hidden="true"></i>
                Map
              </label>
            </li>
            <li>
              <input
                onChange={onHandleView}
                type="radio"
                value="Grid"
                name="content-swith"
              />
              <label>
                <i className="fa fa-th" aria-hidden="true"></i>
                Grid
              </label>
            </li>
            <li>
              <input
                onChange={onHandleView}
                type="radio"
                value="List"
                name="content-swith"
              />
              <label>
                <i className="fa fa-list-ul" aria-hidden="true"></i>
                List
              </label>
            </li>
          </ul>
        </div>

        <div className="properties-block">
          <div
            onScrollCapture={() => {
              setActiveMarker(null);
            }}
            id="listBlock"
            className={
              (contentView === "Map" && "left-block") ||
              (contentView === "Grid" && "left-block grid-view") ||
              (contentView === "List" && "left-block list-view")
            }
            ref={propertiesMainRef}
          >
            <div className="properties-container">
              <h6 className="no-of-properties">
                {propertiesData.length} Properties
              </h6>
              {renderSwitchView()}
            </div>
          </div>

          {contentView === "Map" && (
            <div className="map-block">
              <MapComponent
                setActiveModalId={setActiveModalId}
                isRedirect={false}
                handleActiveMarker={handleActiveMarker}
                activeMarker={activeMarker}
                setActiveMarker={setActiveMarker}
                propertiesData={
                  propertiesData
                    ? propertiesData.slice(
                        (paginationObj.current - 1) * paginationObj.pageSize,
                        paginationObj.pageSize * paginationObj.current
                      )
                    : []
                }
                selectedSearchValue={selectedSearchValue}
                onFilterPropertiesData={onFilterPropertiesData}
              ></MapComponent>
            </div>
          )}
        </div>
      </section>

      <PropertyDetailQuickView
        activeModalId={activeModalId}
        setActiveModalId={setActiveModalId}
      />
    </>
  );
};

export default BuyProperties;
