import { useEffect } from "react";
import "./index.css";
import { useState } from "react";
import { PROPERTIES_URL } from "../../Global/API's";
import PropertyItem from "../../Components/PropertyItem";
import { useParams } from "react-router-dom";
import agentsData from "../../agentsData.json";

const Agentdetails = () => {
  const { id } = useParams();
  const [propertiesData, setPropertiesData] = useState(null);
  const [agentDetails, setAgentDetails] = useState(null);

  const getPropertiesData = async () => {
    const URL = `${PROPERTIES_URL}?limit=4&skip=0`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const responsive = await fetch(URL, options);

    if (responsive.ok) {
      const data = await responsive.json();
      setPropertiesData(data);
    }
  };

  useEffect(() => {
    getPropertiesData();
    const data = agentsData.filter((item) => item.id === Number(id));
    setAgentDetails(data[0]);
  }, [id]);

  return (
    <main className="agent-details-page">
      <section className="agent-banner agent-details-banner">
        <div className="container-fluid">
          <div className="text-content">
            <h1>Agents</h1>
            <p>
              Like us? Love us? Just want to say hello? We’d like to hear from
              you!
            </p>
          </div>
        </div>
      </section>

      {agentDetails && (
        <section className="agent-details">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <div className="left-block">
                  <h2>{agentDetails.name}</h2>
                  <h5>Real Estate Sales person</h5>
                  <div className="text-container">
                    <h5>DRE# 01986308</h5>
                    <p>
                      As a full time professional real estate agent, I pride
                      myself on offering superior personal service before,
                      during and after your transaction. Knowledge, commitment,
                      honesty, expertise and professionalism are the cornerstone
                      of my business. Let me earn your trust, your business and
                      most importantly your friendship. Don’t make another move
                      without me. I guarantee you will see the difference
                      quality service makes. I look forward to working with you!
                    </p>
                  </div>
                  <div className="text-container">
                    <h5>Contact</h5>

                    <form>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <input
                            type="text"
                            placeholder="First Name"
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <input
                            type="text"
                            placeholder="Last Name"
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <input
                            type="email"
                            placeholder="Email Address"
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <input
                            type="text"
                            placeholder="Phone"
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-12 mb-3">
                          <textarea
                            className="form-control"
                            placeholder="Message"
                          ></textarea>
                          <p>
                            This site is protected by reCAPTCHA and the Google
                            Privacy Policy and Terms of Service apply.
                          </p>
                          <button className="btn" type="submit">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="profile-details agent">
                  <figure>
                    <img src={agentDetails.profileImg} alt="" />
                  </figure>
                  <div className="text-block">
                    <h4>{agentDetails.name}</h4>
                    <h5>{agentDetails.occupation}</h5>
                    <p>
                      Cell Phone :
                      <a href={`tel:${agentDetails.cell}`}>
                        {" "}
                        {agentDetails.cell}
                      </a>
                    </p>
                    <p>
                      Office Phone :
                      <a href={`tel:${agentDetails.phone}`}>
                        {" "}
                        {agentDetails.phone}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {propertiesData && (
        <section className="agent-listsings">
          <div className="container-fluid">
            <h2>Agent Listings</h2>
            <p>View the latest properties listed with Agent.</p>

            <ul className="property-listings">
              {propertiesData.map((item, index) => (
                <PropertyItem key={index} eachProperty={item} />
              ))}
            </ul>
          </div>
        </section>
      )}
    </main>
  );
};

export default Agentdetails;
