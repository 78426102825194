import agentsData from "../../agentsData.json";
import Agent from "../../Components/Agent";
import "./index.css";

const AgentFinder = () => {
  return (
    <>
      <section className="agent-banner">
        <div className="container-fluid">
          <div className="text-content">
            <h1>Find an Agent</h1>
            <p>
              Find an agent with local expertise and block-by-block knowledge of
              every neighborhood in United States.
            </p>
          </div>
          <form>
            <div className="form-group">
              <label>
                <input type="radio" checked name="search" />
                <p>Location</p>
              </label>
              <label>
                <input type="radio" name="search" />
                <p>Name</p>
              </label>
            </div>
            <div className="search-container">
              <input
                className="form-control"
                placeholder="Search by city, state or zip"
                type="text"
              />
              <button className="btn" type="button">
                <i className="fa fa-search"></i> Find Agent
              </button>
            </div>
          </form>
        </div>
      </section>
      <section className="agents-container">
        <div className="container-fluid">
          <h2>Real Estate Agents in Selzer Realty</h2>
          <p>
            The top agents are knowledgeable about your market and can help you
            every step of the way.
          </p>
          <div className="agents-block">
            {agentsData.map((agent, index) => (
              <Agent key={index} agent={agent} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AgentFinder;
