import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "jquery-match-height/dist/jquery.matchHeight-min.js";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import BuyProperties from "./Pages/BuyProperties";
import PropertyDetails from "./Pages/PropertyDetails";
import Blogs from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import "../node_modules/fontawesome-4.7/css/font-awesome.min.css";
import "./responsive.css";
import FeaturedListing from "./Pages/FeaturedListing";
import ComingSoon from "./Components/ComingSoon";
import AgentFinder from "./Pages/AgentFinder";
import Agentdetails from "./Pages/AgentDetails";
import ScrollToTop from "./Components/scrollToTop/scrollToTop";
import PropetyDataContext from "./Context";

const App = () => {
  const [isPropertiesPage, setIsPropertiesPage] = useState(false);
  const [rangeFilterList, setRangeFilterList] = useState({
    minPrice: "",
    maxPrice: "",
    minBeds: "",
    maxBeds: "",
    minBaths: "",
    maxBaths: "",
    minDaysOnSite: "",
    maxDaysOnSite: "",
    minSqft: "",
    maxSqft: "",
    filterPropertyTypeData: [
      "Residential",
      "Business Opportunity",
      "Commercial Sale",
      "Commercial Lease",
      "Residential Income",
      "Land",
      "Mobile in Park/Floating Home",
      "Residential Lease",
      "Manufactured In Park",
    ],
  });

  const isActivePropertiesPage = () => {
    setIsPropertiesPage(true);
  };

  const isInActivePropertiesPage = () => {
    setIsPropertiesPage(false);
  };

  return (
    <PropetyDataContext.Provider
      value={{ rangeFilterList, setRangeFilterList }}
    >
      <Header />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/buy"
          element={
            <BuyProperties
              isInActivePropertiesPage={isInActivePropertiesPage}
              isActivePropertiesPage={isActivePropertiesPage}
            />
          }
        />
        <Route
          exact
          path="/rent"
          element={
            <BuyProperties
              isInActivePropertiesPage={isInActivePropertiesPage}
              isActivePropertiesPage={isActivePropertiesPage}
            />
          }
        />
        <Route
          exact
          path="/sell"
          element={
            <BuyProperties
              isInActivePropertiesPage={isInActivePropertiesPage}
              isActivePropertiesPage={isActivePropertiesPage}
            />
          }
        />
        <Route
          exact
          path="/property-details/:id"
          element={<PropertyDetails />}
        />
        <Route exact path="/featured-listing" element={<FeaturedListing />} />
        <Route exact path="/blog" element={<Blogs />} />
        <Route exact path="/blog/:title/:id" element={<BlogDetails />} />
        <Route exact path="/agent-finder" element={<AgentFinder />} />
        <Route exact path="/agent-details/:id" element={<Agentdetails />} />
        <Route exact path="*" element={<ComingSoon />} />
      </Routes>
      {!isPropertiesPage && <Footer />}
    </PropetyDataContext.Provider>
  );
};

export default App;
